:root {
  --white: #fff;
  --black: #000;
  --brownish-orange: #cb7a2a;
  --greyish: #b9b9b9;
  --light-grey: #ededed;
  --medium-grey: #646462;
  --dark-slate-blue: #2c4173;
  --navy: #001623;
  //------
  --primary-regular: #183f73;
  --primary-hover: #05234c;
  --primary-pressed: #020d1c;
  --primary-disabled: #e8ecf1;
  --secondary-blue: #26538f;
  --secondary-orange: #ed7916;
  --secondary-green: #769d2d;
  --secondary-turquoise: #18b8a3;
  --secondary-purple: #6a16ed;
  --secondary-azure: #147ce4;
  --secondary-red: #ed3b16;
  --black-100: #020d1c;
  --black-80: #343d49;
  --black-60: #676e77;
  --black-40: #9a9ea4;
  --black-20: #cccfd2;
  --black-05: #f2f3f3;
  --input-regular-border: #f3f4f5;
  --input-active-border: #183f73;
  --input-disabled-border: #f3f4f5;
  --dark-yellow: #C59A33;
  --dark-red: #BC401E;
  --dark-blue: #2C70BA;
  --dark-purple: #7B448E;
  
}